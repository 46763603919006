
// import React, { useEffect, useState, useMemo, useRef, createRef } from "react";
// import { MapContainer, TileLayer, useMap, Marker, FeatureGroup, LayerGroup, LayersControl, Popup, Circle, Rectangle } from "react-leaflet";
// import L from 'leaflet'
// import MarkerClusterGroup from 'react-leaflet-cluster'

// import 'leaflet/dist/leaflet.css';
// const markerIcon = new L.Icon({
//     iconUrl: require("./marker.png"),
//     iconSize: [40, 40],
//     iconAnchor: [17, 46], //[left/right, top/bottom]
//     popupAnchor: [0, -46], //[left/right, top/bottom]
// })

// const markerIcon2 = new L.Icon({
//     iconUrl: require("./favicon.png"),
//     iconSize: [40, 40],
//     iconAnchor: [17, 46], //[left/right, top/bottom]
//     popupAnchor: [0, -46], //[left/right, top/bottom]

// })

// const ReportMap = ({ props }) => {
//     const [plots, setPLots] = useState([])
//     const [InitialLat, setInitialLat] = useState(27.172183)
//     const [InitialLong, setInitialLong] = useState(81.2148522)
//     const [zoom, setZoom] = useState(4)
//     const mapRef = createRef()

//     useEffect(() => {
//         console.log("props", props)
//         setPLots(props)
//         setZoom(4)
//         setInitialLat(27.172183)
//         setInitialLong(81.2148522)


//         console.log("abcdef", mapRef.current)

//     }, [props])


//     return (
//         <div >

//             <MapContainer
//                 ref={mapRef}

//                 style={{ height: '500px', width: "100%" }}
//                 center={[InitialLat, InitialLong]}
//                 zoom={zoom}
//                 scrollWheelZoom={true}

//             >
//                 <TileLayer
//                     url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
//                     attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//                 />

// <LayersControl position="topright">
//     <LayersControl.Overlay checked name="Layer group with point 1">

//         <MarkerClusterGroup
//             chunkedLoading

//         >
//             {
//                 plots.map((location) => {
//                     { console.log("location", location) }
//                     { console.log("location2222", location[location.length - 1].lat) }

//                     return <Marker icon={markerIcon} position={[location[0].lat, location[0].long]} />


//                 })
//             }

//         </MarkerClusterGroup>
//     </LayersControl.Overlay>

//     <LayersControl.Overlay name="Feature group point2">
//         <MarkerClusterGroup
//             chunkedLoading
//         >
//             {
//                 plots.map((location) => {
//                     { console.log("location2222", location[location.length - 1].lat) }

//                     return <Marker icon={markerIcon2} position={[location[location.length - 1].lat, location[location.length - 1].long]} />


//                 })
//             }


//         </MarkerClusterGroup>
//     </LayersControl.Overlay>
// </LayersControl>


//             </MapContainer>

//         </div>

//     )
// }
// export default ReportMap






import { useLeafletContext } from '@react-leaflet/core'
import L, { bounds } from 'leaflet'
import { useEffect, useRef, useState } from 'react'
import { MapContainer, TileLayer, useMap, Marker, FeatureGroup, LayerGroup, LayersControl, Popup, Circle, Rectangle } from "react-leaflet";

import MarkerClusterGroup from 'react-leaflet-cluster'


import 'leaflet/dist/leaflet.css';

const markerIcon = new L.Icon({
    iconUrl: require("./marker.png"),
    iconSize: [40, 40],
    iconAnchor: [17, 46], //[left/right, top/bottom]
    popupAnchor: [0, -46], //[left/right, top/bottom]
})

const markerIcon2 = new L.Icon({
    iconUrl: require("./marker8.png"),
    iconSize: [40, 40],
    iconAnchor: [17, 46], //[left/right, top/bottom]
    popupAnchor: [0, -46], //[left/right, top/bottom]

})

function getBounds(props) {
    return L.latLng(props.center).toBounds(props.size)
}

function Square(props) {
    const context = useLeafletContext()
    const squareRef = useRef()
    const propsRef = useRef(props)

    useEffect(() => {

        squareRef.current = new L.Rectangle(getBounds(props))
        const container = context.layerContainer || context.map
        container.addLayer(squareRef.current)

        return () => {
            container.removeLayer(squareRef.current)
        }
    }, [])

    useEffect(() => {
        if (
            props.center !== propsRef.current.center ||
            props.size !== propsRef.current.size
        ) {
            squareRef.current.setBounds(getBounds(props))
        }
        if (props.bounds.length > 1){
            context.map.fitBounds(props.bounds)
        }
        propsRef.current = props
        
    }, [props.center, props.size, props.bounds])

    return null
}


function ReportMap(props) {
   console.log("props",props)
    const [center, setCenter] = useState([28.576592977786234, 77.31433377113422])

    const [plots, setPLots] = useState([])
    const [plotsLayer2, setPlotLayer2] = useState([])

    useEffect(() => {
        const tempPlots = []
        const marker2 = []
      
        props.props.map((plot) => {
            if(Array.isArray(plot))
            {
            console.log("plot1",plot)
            if(plot[0].lat&&plot[0].long){
            tempPlots.push([plot[0].lat, plot[0].long])
            }
            if( plot[plot.length - 1].lat && plot[plot.length - 1].long){
            marker2.push([plot[plot.length - 1].lat, plot[plot.length - 1].long])
            }
            }
        })
    
        setPLots(tempPlots)
        setPlotLayer2(marker2)
        
    }, [props.props])
   console.log("plots",plots)
   console.log("final plotsLayer2",plotsLayer2)

    return (
        <>
            <MapContainer center={center} style={{ height: "50vh" }} zoom={13}>
                <TileLayer
                    url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Square center={center} size={0} bounds={plots} />

                <LayersControl position="topright">
                    <LayersControl.Overlay checked name="Layer group with point 1">
                        <MarkerClusterGroup
                            chunkedLoading
                        >
                            {
                                plots.map((location) => {

                                    return <Marker icon={markerIcon} position={location} />
                                })
                            }

                        </MarkerClusterGroup>
                    </LayersControl.Overlay>

                    <LayersControl.Overlay name="Feature group point2">
                        <MarkerClusterGroup
                            chunkedLoading
                        >
                            {
                                plotsLayer2.map((location) => {
                                    // { console.log("location2222", location[location.length - 1].lat) }

                                    return <Marker icon={markerIcon2} position={location} />
                                })
                            }


                        </MarkerClusterGroup>
                    </LayersControl.Overlay>
                </LayersControl>
            </MapContainer>
        </>
    )
}

export default ReportMap