import React, { useState } from "react"

const MapLoader = () => {

  return (
    <div className="generic-card">
      <div className="cardmain loading"></div>
    </div>
  )
}


export default MapLoader

