
import React, { useState, useEffect, useRef } from "react"

import { Card, CardBody, Row, Col, Badge, Input, Label, Modal, ModalBody,Container } from "reactstrap"
import Report from "./Report"



const App=()=>{
  return (
    <div>
     
    <Report/>
    </div>
    
  )
}
export default App