import axios from "axios"
const baseUrl ="https://api-prod.agrani.io"

// "https://api-prod.agrani.io"



// "http://api-dev.agrani.io"
// "https://api-prod.agrani.io"

console.log("baseUrl",baseUrl)
const axiosApi = axios.create({
  baseURL: baseUrl,
})
const axiosApi2 = axios.create({
  baseURL: "http://api-dev.agrani.io",
})
axiosApi.interceptors.response.use( 
    response => response,
    error => Promise.reject(error)
  )
  export const SurveyService = {
    // Get all Data Call
    GetSurveyInspectionDataFull,
    GetSurveyType,
    GetState,GetDistrict,
    GetBlock,
    AgentList,
    getDataWithAgentId,
    GetGTSurveyInspectionParticular,
    GetFilteredDataWithFilters,
    NewSUrveyDataApi
  }
  export async function GetSurveyType(config = {}) {
    return axiosApi
      .get(`survey/master/survey-forms`, { ...config })
      .then(response => response.data)
  }
  export async function GetSurveyInspectionDataFull(ST, config = {}) {
    return axiosApi
      .get(`survey/records/${ST}/?limit=600&offset=10`, { ...config })
      .then(response => response.data)
  }
  export async function NewSUrveyDataApi(ST, config = {}) {
    return axiosApi
      .get(`survey/specific-survey-records/?formName=${ST}`, { ...config })
      .then(response => response.data)
  }
  export async function GetGTSurveyInspectionParticular(ST, id, config = {}) {
    return axiosApi
      .get(`survey/records/${ST}/${id}`, { ...config })
      .then(response => response.data)
  }
  export async function getDataWithAgentId(ST,AgentId, config = {}) {
    return axiosApi
      .get(`survey/specific-survey-records/?formName=${ST}&AgentId=${AgentId}`, { ...config })

      .then(response => response.data)
  }
  export async function GetFilteredDataWithFilters(ST,StateId,Level3Id,Level4Id, config = {}) {
    return axiosApi
      // .get(`survey/records/${ST}?StateId=${StateId}&Level3Id=${Level3Id}&Level4Id=${Level4Id}`, { ...config })
      .get(`survey/specific-survey-records/?formName=${ST}&StateId=${StateId}&Level3Id=${Level3Id}&Level4Id=${Level4Id}`, { ...config })

      .then(response => response.data)
  }
  export async function GetState( config = {}) {
    return axiosApi
      .get(`master/states`, { ...config })
      .then(response => response.data)
  }
  export async function GetDistrict(id, config = {}) {
    return axiosApi
      .get(`master/states/${id}/districts`, { ...config })
      .then(response => response.data)
  }
  export async function GetBlock(id, config = {}) {
    return axiosApi
      .get(`master/districts/${id}/blocks`, { ...config })
      .then(response => response.data)
  }
  export async function AgentList( config = {"headers":{"Authorization":"WyIzIiwiJDUkcm91bmRzPTUzNTAwMCQxNjhXL0h2YjcuZUN5dllpJGZEMGYzaTdpdFlVLkJTLmdGcmRJZHlkRXNQYWlJakdFdUkvc2cva1ZIODgiXQ.Y_GMMg.g4CB58IIsS2WFDXy3q7BHwl3koM"}}) {
    return axiosApi
      .get(`enrolment/users`, { ...config })
      .then(response => response.data)
  }