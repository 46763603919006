import React, { useState, useEffect, useRef } from "react"

import {
    Card, CardBody, Row, Col, Badge, Input, Label, Container, NavLink, TabContent,
    TabPane,
} from "reactstrap"
// import { Button,Row,Col,Container,Card,CardBody } from 'react-bootstrap';

import ReportMap from "./ReportMap"
import { SurveyService } from "./SurveyService"
import {
    Button, Form, Modal, ModalBody, Nav, NavItem,Spinner
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MUIDataTable from "mui-datatables";
import agranilogo from "./agrani-logo.png"
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import TableLoader from "./Loader"
import MapLoader from "./MapLoader"
import Select from 'react-select'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';




const Report = () => {
    // var Status =
    //     "?limit=10000&SurveyStatus__in=Completed,Partially Completed,Rejected,Approved"
    const [SurveyType, setSurveyType] = useState([])
    const [selectSurvey, setSelect] = useState("GT")
    const [SurveyData, setSurveyData] = useState()
    const [showPlots, setshowPlots] = useState([])
    const [showComponent, setShowComponent] = useState(false)
    const [stateList, setStateList] = useState([])
    const [stateId, setStateId] = useState("")
    const [districtList, setDistrictList] = useState([])
    const [districtId, setDistrictId] = useState("")
    const [blockList, setBlockList] = useState([])
    const [blockId, setBlockId] = useState("")
    const [agentList, setAgentList] = useState([])
    const [agentId, setAgentId] = useState(-1)
    const [agentName, setAgentName] = useState("")
         const [stateName, setStateName] = useState("")
        const [districtName, setDistrictName] = useState("")
             const [blockName, setBlockName] = useState("")

    const [tableData, setTableData] = useState([])
    const [errorMessage, showErrorMessage] = useState(false)
    const [errorMessageState, showErrorMessageState] = useState(false)
    const [photoIndex, setphotoIndex] = useState({})
    const [isGallery, setisGallery] = useState(false)
    const [modalData, showModalData] = useState([])

    const [show, setShow] = useState(false);
    const [SurveyInspectionSgl, setSurveyInspectionSgl] = useState([]);
    const [showLoader, setshowLoader] = useState(false)
    const [agentSpinner, setagentSpinner] = useState(true)
    const [disableDropdown,setdisableDropdown] = useState(true)
    const [defaultLine,setDefaultLine]=useState(false)

    const agentItems = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];
   
    const handleClose = () =>{ 
        setShow(false)
        setDefaultLine(false)
    
    };
    const handleShow = (userId) => {
        setShow(true)
        setactiveTab(1)
        setDefaultLine(false)
        SurveyService.GetGTSurveyInspectionParticular(ST, userId)
            .then(data => {
                showModalData(data.data)
                console.log("modal Data", data)
                setSurveyInspectionSgl(data)
             
            })
            .catch(error => {
                console.warn("Not good data fetch :(")
            })

    };

    const [activeTab, setactiveTab] = useState(1)

    const handleColor = () => {
        if (activeTab == 1)
            return "coloredClass"
        if (activeTab == 2)
            return "coloredClass"
        else
            return "basicClass"
    }



    let unmounted = useRef(false)

    var ST = selectSurvey
    const GetSurveyTypeList = () => {
        SurveyService.GetSurveyType().then(data => {
            setSurveyType(data)
            setdisableDropdown(false)

        })
    }
    useEffect(() => {
        SurveyService.GetState().then(data => {
            setStateList(data.data)

        })
      
    }, [])

    const SurveyInspection = () => {
        showErrorMessage(false)
        setAgentId(-1)
        setStateId("")
        setDistrictId("")
        setBlockId("")
        setAgentName("")
        setStateName("")
        setDistrictList([])
        setBlockList([])
        setDistrictName("")
        setBlockName("")
        showErrorMessageState(false)

        console.log("mkk", stateId)
        setshowLoader(true)
       

        SurveyService.NewSUrveyDataApi(ST)
        .then(data => {

           console.log("new data",data)
         
           setSurveyData(data.data)
           setTableData(data.data)
           setshowLoader(false)
           setdisableDropdown(false)
        
          


        })
        .catch(error => {
            setshowLoader(false)
            setdisableDropdown(false)
            setSurveyData()
            setTableData([])
            setshowPlots([])
            
            console.warn("Not good data fetch :(")
        })  
          
    }
    useEffect(() => {
        console.log("filter SurveyData", SurveyData)
        const FilteredLatLong = []

        // SurveyData.results.map((item)=>{
        //     if(item?.Inspection){
        //         ST !== 'CCE' ? FilteredLatLong.push(item.Inspection.LatLong) : (
        //             item.Inspection?.Part1 ? FilteredLatLong.push(item.Inspection.Part1.LatLong) : FilteredLatLong.push(item.Inspection?.Part2.LatLongPart2)
        //         )
        //     }
        // }
        if (SurveyData != undefined) {
            SurveyData.map((item) => {
                console.log("item", item.Inspection__LatLong)
               
                    if (ST === "ILA" || ST === "CCEINT" || ST === "GT" || ST === "CHM" || ST === "ICV" || ST==="CCE") {
                        if (Array.isArray(item.Inspection__LatLong)) {
                            FilteredLatLong.push(item.Inspection__LatLong)

                        }
                  
                    // if (ST === "CCE") {
                       
                    //     if (item.Inspection.Part1 && item.Inspection.Part1.LatLong.length != 0) {
                    //         console.log('enter PART1', item.Inspection.LatLong)

                    //         FilteredLatLong.push(item.Inspection.Part1.LatLong)

                    //     }
                    //     if (item.Inspection.Part2 && item.Inspection.Part2.LatLongPart2.length != 0) {
                    //         console.log('enter PART2', item.Inspection.LatLong)

                    //         FilteredLatLong.push(item.Inspection.Part2.LatLongPart2)

                    //     }
                    // }
                }
            })
            console.log("FilteredLatLong", FilteredLatLong)
            setshowPlots(FilteredLatLong)
            // console.log("plots", showPlots)
            setShowComponent(true)
        }
    }, [SurveyData])

    const handleSelectChange = event => {
        const value = event.target.value
        setSelect(value)
        
    }
    useEffect(() => {
        GetSurveyTypeList()
        unmounted = false
        if (ST !== undefined) {
            setdisableDropdown(true)

            SurveyInspection()
        }
        return () => {
            unmounted = true
        }
    }, [ST])
    const handleSelectiveState = (e) => {
        console.log("state", e)
        if (ST == undefined) {
            showErrorMessageState(true)
        }
        setBlockId("")
        setAgentId(-1)
        setAgentName("")
        setDistrictName("")
        setDistrictId("")
         setBlockId("")
        setBlockName("")
        setBlockList([])
        setStateId(e.value)
        setStateName(e.label)

        setdisableDropdown(true)

    }
    useEffect(() => {
        if(ST!==undefined && stateId!="")
        {
        SurveyService.GetDistrict(stateId).then(data => {
            setDistrictList(data.data)
            setdisableDropdown(false)

        })
        setshowLoader(true)

        SurveyService.GetFilteredDataWithFilters(ST,stateId,"","").then(data => {
           setTableData(data.data)
           console.log("state filter data",data)
           setSurveyData(data.data)
           setshowLoader(false)
           setdisableDropdown(false)


        })
        .catch(error => {
            setshowLoader(false)
            setdisableDropdown(false)
            setSurveyData()
            setTableData([])
            console.warn("Not good data fetch :(")
            setshowPlots([])
        })  
    }
     
      



    }, [stateId])
    const handleSelectiveDistrict = (e) => {
        console.log("district", e)

        setDistrictId(e.value)
        setDistrictName(e.label)
       
        setBlockId("")
        setBlockName("")
        setdisableDropdown(true)

        console.log("districtId", e.value)
    }
    useEffect(() => {
        if(ST!==undefined && districtId!=""){
        SurveyService.GetBlock(districtId).then(data => {
            setBlockList(data.data)
        })
        setshowLoader(true)

        SurveyService.GetFilteredDataWithFilters(ST,stateId,districtId,"").then(data => {
            setTableData(data.data)
            console.log("state filter data",data)
            setSurveyData(data.data)
            setshowLoader(false)
            setdisableDropdown(false)


         })
         .catch(error => {
            setshowLoader(false)
            setdisableDropdown(false)
            setSurveyData()
            setTableData([])
            console.warn("Not good data fetch :(")
            setshowPlots([])
        })  

        }


    }, [districtId])
    const handleSelectiveBlock = (e) => {
        console.log("block", e)
        
        setBlockId(e.value)
        setdisableDropdown(true)
        setBlockName(e.label)
        console.log("blockId", e.value)
    }
    useEffect(() => {
       
       if(ST!==undefined && blockId!=""){
        setshowLoader(true)

        SurveyService.GetFilteredDataWithFilters(ST,stateId,districtId,blockId).then(data => {
            setTableData(data.data)
            console.log("state filter data",data)
            setSurveyData(data.data)
            setshowLoader(false)
            setdisableDropdown(false)


         })
         .catch(error => {
            setshowLoader(false)
            setdisableDropdown(false)
            console.warn("Not good data fetch :(")
            setSurveyData()
            setTableData([])
            setshowPlots([])
        })  
        }



    }, [blockId])
    useEffect(() => {
        SurveyService.AgentList().then(data => {
            setAgentList(data.data)
            setagentSpinner(false)
            console.log("agent", data)
        })
    }, [])
    const handleSelectiveAgent=(e)=>{
        console.log("I am Selected",e)
        console.log("agent789", e.value)
        if (ST == undefined) {
            console.log("TRRR", ST)
            showErrorMessage(true)
            setAgentId(-1)
            setAgentName("")
            return
        }
        setStateId("")
        setDistrictId("")
        setBlockId("")
        setDistrictList([])
        setBlockList([])
        setStateName("")
         setDistrictName("")
        setBlockName("")
        setAgentId(e.value)
        setAgentName(e.label)
        setdisableDropdown(true)

        console.log("agentId", e.value)
    }
    const handleAgent = (e) => {
        console.log("agent789", e.target.value)
        if (ST == undefined) {
            console.log("TRRR", ST)
            showErrorMessage(true)
            setAgentId(-1)
            
            return
        }
        setStateId("")
        setDistrictId("")
        setBlockId("")
        setDistrictList([])
        setBlockList([])
        setAgentId(e.target.value)
        console.log("agentId", e.target.value)
    }
    useEffect(() => {
        console.log("ST Check", ST)
        if (ST != undefined && agentId != -1) {
            setshowLoader(true)

            SurveyService.getDataWithAgentId(ST, agentId).then(data => {

                console.log("agent Data", data.data.results)
                setTableData(data.data)
                setSurveyData(data.data)
                setshowLoader(false)
                setdisableDropdown(false)


            })
        }

    }, [agentId])
const handleAgentOptions=()=>{
    console.log("agent options")
    let responseAgentArray=[]   
    agentList.map((agent) => {
        responseAgentArray.push({ value: agent.id, label: agent.name },)
    })
    console.log("responseAgentArray",responseAgentArray)
    return responseAgentArray
}
const handleStateOptions=()=>{
    console.log("state options")
    let responseAgentArray=[]  

    stateList.map((state) => {
        responseAgentArray.push({ value: state.state_code, label: state.state_name },)
    })
    console.log("responseAgentArray",responseAgentArray)
    return responseAgentArray
}
const handleDistrictOptions=()=>{
    console.log("state options")
    let responseAgentArray=[]   
    districtList.map((district) => {
        responseAgentArray.push({ value: district.level_3_code, label: district.level_3_name },)
    })
    console.log("responseAgentArray",responseAgentArray)
    return responseAgentArray
}
const handleBlockOptions=()=>{
    console.log("state options")
    let responseAgentArray=[]   
    blockList.map((block) => {
        responseAgentArray.push({ value: block.level_4_code, label: block.level_4_name },)
    })
    console.log("responseAgentArray",responseAgentArray)
    return responseAgentArray
}

const handleDynamicLine=()=>{
    console.log("finally 0",SurveyInspectionSgl.Inspection.Part2.Media)
    var foundData=[]
    if(SurveyInspectionSgl.Inspection.Part2.Media){
    foundData=SurveyInspectionSgl.Inspection.Part2.Media.filter((list, index) =>{
          if(list.ImagePath!=="")
          {
            console.log("camel",list.ImagePath)
            return list.ImagePath
          }
    })
}
    console.log("foundData",foundData)
    if (foundData.length!=0)
    {
    console.log("enter1",foundData)
    // setDefaultLine(false)
    return false
      
    }
     else 
     {
        console.log("enter2",foundData)
        return true
        // setDefaultLine(true)
          
     }
}
// const handleDynamicLineExceptCCE=()=>{
//     var foundData=[]
//     console.log("energy",SurveyInspectionSgl.Inspection)
//     if(SurveyInspectionSgl.Inspection!=undefined){
//     foundData=SurveyInspectionSgl.Inspection.Media.filter((list, index) =>{
//           if(list.ImagePath!=="")
//           {
//             return list.ImagePath
//           }
//     })
// }
//     if (foundData.length!=0)
//     {
    
//     return false
      
//     }
//      else 
//      {
//         return true
        
          
//      }
// }

    const options = {
        filterType: 'checkbox',
      
    };
    // const options = {
    //     page: 100,
    //     rowsPerPage: 100,
    //     rowsPerPageOptions: [50, 100, 1000, 1500],
    //     filterType: "dropdown",
    //     responsive: 'vertical',
    //     selectableRows: 'none',
    //     filter: true,
    //     textLabels: {
    //       pagination: {
    //         next: "Next >",
    //         previous: "< Previous",
    //         rowsPerPage: "Total Per Page",
    //         displayRows: "OF",
    //       },
    //     },
    //     onChangePage(currentPage) {
    //       console.log({ currentPage })
    //     },
    //     onChangeRowsPerPage(numberOfRows) {
    //       console.log({ numberOfRows })
    //     },

    //   };

    const state = {
        page: 0,
        count: 1,
        sortOrder: {},
        data: [['Loading Data...']],
        columns: [
            {
                name: 'Year',
                label: 'Year',
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        // console.dir(tableMeta);
                        return value;
                    },
                },
            },
            {
                name: 'Season',
                label: 'Season',
                dataIndex: ['Season'],
                options: {
                    filter: true,
                    sort: false,
                }
            },
           
            {
                name: "StateName",
                label: "State Name",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "L3",
                label: "L3",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "L4",
                label: "L4",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "L5",
                label: "L5",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "L6",
                label: "L6",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "L7",
                label: "L7",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "SurveyNo",
                label: "Survey No",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "CropName",
                label: "Crop Name",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "SurveyStatus",
                label: "Survey Status",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            //   {
            //     name: "FormName",
            //     label: "Form Name", 
            //     options: {
            //       filter: false,
            //       sort: false,
            //     }
            //   },
            {
                name: "view_details",
                label: "View Details",
                options: {
                    filter: false,
                    sort: false,
                    download: false,
                }
            },
        ],
        isLoading: false,
    }
    { console.log("tableData", tableData) }
    const getSrcData = tableData && tableData.map((list, index) => (list !== undefined ? {
        // Id: list.Id,
        Year: list.Year,
        Season: list.Season,
        ClientId: list.ClientId,
        StateName: list.StateName,
        L3: list.Level3Name,
        L4: list.Level4Name,
        L5: list.Level5Name,
        L6: list.Level6Name,
        L7: list.Level7Name,
        SurveyNo: list.SurveyNo,
        CropName: list.CropName,
        FormName: list.FormName,
        SurveyStatus: (<button className={`font-size-10 text-wrap p-1 ${list.SurveyStatus === "Initiated" || list.SurveyStatus === "Pending" ? 'badge-soft-info' : list.SurveyStatus === "Allocated" || list.SurveyStatus === "Partially Completed" ? 'badge-soft-warning' : list.SurveyStatus === "Completed" ? 'badge-soft-success' : list.SurveyStatus === "Error" ? 'badge-soft-danger' : 'badge-soft-dark'}`}>
            {list.SurveyStatus}
        </button>),
        view_details: <div onClick={(e) => handleShow(list.Id)}> <a href="#"><RemoveRedEyeIcon style={{color:"orange"}}/> </a></div>
        // view_details: (list.SurveyStatus === "Partially Completed" || list.SurveyStatus === "Completed" ?
        //   <Link className="text-center" to={`Survey-single-details?id=${list.Id}`} target="_blank"  disabled>
        //     <i className="font-size-14 far fa-eye ms-2" aria-hidden="true"></i>
        //   </Link>
        //   : <i className="font-size-14 far fa-eye-slash ms-2" aria-hidden="true"></i>),

    } : null
    )
    )


    return (
        <React.Fragment>
            <div className="page-content " style={{width:"",marginTop:"10px"}}>
                <Container fluid >
                    <div className="container-fluid">
                        <Col lg="12" className="text-end p-2" style={{marginBottom:"",fontSize:"16px",color:"#707070"}}>
                            <Label className="me-3">
                                <h5  style={{fontSize:"16px"}}>Select Survey Type</h5>
                            </Label>{" "}
                            <br />

                            {SurveyType.data &&
                                SurveyType.data.results.map((slist, index) => (
                                    <div
                                        key={index}
                                        className="form-check form-check-inline font-bold"
                                    >
                                        <Input
                                            type="radio"
                                            id={slist.SurveyName}
                                            value={slist.SurveyName}
                                            //  value={selectSurvey}
                                             checked={slist.SurveyName==selectSurvey?true:false}
                                            //  name={`${selectSurvey}`}
                                            name="surveytype"
                                            className="form-check-input"
                                            onChange={event => handleSelectChange(event)}
                                            disabled={slist.SurveyName==="IRV" || slist.SurveyName==="SS" ||slist.SurveyName==="ILA" ||slist.SurveyName==="ICV" ? true:false}
                                        />
                                        <Label
                                            className="form-check-label"
                                            htmlFor={slist.SurveyName}
                                        >
                                            {slist.SurveyName}
                                        </Label>
                                    </div>
                                ))}
                                {console.log("garb",selectSurvey)}
                              
                        </Col>

                        <div>
                            <Row lg="12" style={{ marginTop: "",padding:"18px",paddingLeft:"0px" }} className={disableDropdown? "":""}>


                                <Col lg="3">
                                <Select menuPlacement="auto" options={handleStateOptions()} onChange={handleSelectiveState} placeholder={stateId===""?"Select State":stateName} defaultValue={stateId} value={stateId===""?"Select State":stateName} className={show?"SelectAgentClass2":"SelectAgentClass"} />
                                {errorMessageState ? <p style={{ color: "red" }}>Select Survey Type Before Selecting State</p> : ""}
 
                                </Col>

                                <Col lg="3">
                                  {console.log("placeholder",districtId)}
                                <Select options={handleDistrictOptions()} onChange={handleSelectiveDistrict} placeholder={districtId==""?"Select District":districtName} defaultValue={districtId} value={districtId===""?"Select District":districtName} className={show?"SelectAgentClass2":"SelectAgentClass"} />
 
                                </Col>
                                <Col lg="3">
                                <Select options={handleBlockOptions()} onChange={handleSelectiveBlock} placeholder={blockId==""?"Select Block":blockName} defaultValue={blockId} value={blockId===""?"Select Block":blockName} className={show?"SelectAgentClass2":"SelectAgentClass"} />
 
                                </Col>











                              
                            

                          
                                <Col lg="3">
                                <Select options={handleAgentOptions()} onChange={handleSelectiveAgent} placeholder={agentId===-1?"Select Agent":agentName} defaultValue={agentId} value={agentId===-1?"Select Agent":agentName} className={show?"SelectAgentClass2":"SelectAgentClass"} />

                                {errorMessage ? <p style={{ color: "red" }}>Select Survey Type Before Selecting Agent</p> : ""}
 
                                </Col>
                            </Row>
                        </div>
                        <Col sm="12" className="px-0 py-3" style={{marginLeft:"-14px"}}>
                            <Card className="mt-3 shadow-map">
                                <CardBody>
                                    <div className=" d-flex">
                                    </div>
                                    {
                                        // showComponent && showPlots.length != 0 ? <ReportMap props={showPlots} /> : <div style={{ textAlign: "center" }}>No Matching Plots Found</div>
                                    }
                                    {!showLoader?
                                       
                                    
                                        showComponent && showPlots.length != 0 ? <ReportMap props={showPlots} /> : <div style={{ textAlign: "center",padding:"16px" }}>No Data Found</div>
                                    
                                    :<MapLoader/>}
                                </CardBody>
                            </Card>
                        </Col>
                        {!showLoader?
                        <Col className="col-12 mt-3 border" style={{marginLeft:"-14px"}}>
                            <MUIDataTable
                                title={"Report List"}
                                tableData
                                data={getSrcData}
                                columns={state.columns}
                                options={options}
                            />
                        </Col>
                        :<TableLoader/>}
                        <Modal show={show} onHide={handleClose}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            size="xl"
                            style={{marginTop:""}}
                        >
                            <Modal.Header closeButton style={{marginTop:""}}>
                                <Modal.Title>Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row><Col lg="1"></Col>
                                    <Col lg="3">
                                        <NavItem
                                            className={activeTab == 1 ? "coloredClass" : "basicClass"}
                                            style={{textAlign:"center"}}
                                        >
                                            <NavLink
                                                onClick={() => {
                                                    setactiveTab(1)
                                                }}
                                            >
                                              {selectSurvey==="CCE"?"Part 1": "Crop Details"}
                                            </NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col lg="3">
                                        <NavItem
                                            className={activeTab == 2 ? "coloredClass" : "basicClass"}
                                            style={{textAlign:"center"}}

                                        >
                                            <NavLink
                                                onClick={() => {
                                                    setactiveTab(2)
                                                }}
                                            >
                                            {selectSurvey==="CCE"?"Part 2": "Other Details"}

                                            </NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col lg="3">
                                    <NavItem  className={activeTab == 3 ? "coloredClass" : "basicClass"}  style={{textAlign:"center"}}>
                                    <NavLink
                                      onClick={() => {
                                        setactiveTab(3)
                                      }}
                                    >
                                      Documents
                                    </NavLink>
                                  </NavItem>
                                    </Col>
                                </Row>
                                <TabContent
                                    activeTab={activeTab}
                                    className="tabClass"
                                    style={{ marginTop: "20px", borderTop: "1px solid lightgrey", borderRadius: "15px" }}
                                >
                                    <TabPane tabId={1}>
                                    {SurveyInspectionSgl.FormName==="CCE"?
                                    <Row>
                                    <Col lg="3" className="mb-2">
                                      <Label>Farmer Name</Label>
                                      <Input
                                        type="text"
                                        name="PlotShape"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.FarmerName
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Farmer Phone</Label>
                                      <Input
                                        type="text"
                                        name="FarmerPhone"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.FarmerPhone
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Farmer Aadhar</Label>
                                      <Input
                                        type="text"
                                        name="FarmerAadhar"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.FarmerAadhar
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Highest Khasra No</Label>
                                      <Input
                                        type="text"
                                        name="khasraNoH"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.khasraNoH
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Selected Khasra No</Label>
                                      <Input
                                        type="text"
                                        name="khasraNoS"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.khasraNoS
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Plot Size</Label>
                                      <Input
                                        type="text"
                                        name="PlotSize"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.PlotSize
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Selected Length </Label>
                                      <Input
                                        type="text"
                                        name="SelectedLength"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.SelectedLength
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Selected Breadth</Label>
                                      <Input
                                        type="text"
                                        name="SelectedBreadth"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.SelectedBreadth
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Harvest Date Probable</Label>
                                      <Input
                                        type="text"
                                        name="AreaCalculated"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.HarvestDateProbable
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Total Area Selected</Label>
                                      <Input
                                        type="text"
                                        name="AreaCalculated"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part1 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part1.totalAreaSelectedField
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="6" className="mb-2">
                                <Label>Have you notified Your District Admin And Co-observer</Label>
                                <Input
                                  type="text"
                                  name="AreaCalculated"
                                  id=""
                                  defaultValue={
                                    SurveyInspectionSgl !== undefined &&
                                    SurveyInspectionSgl.Inspection.Part1 !== undefined
                                      ? SurveyInspectionSgl.Inspection.Part1.IsNotified
                                      : ""
                                  }
                                  disabled
                                />
                              </Col>
                                  </Row>




                                        :<Row>
                                            <Col lg="3" className="mb-2">
                                                <Label>Sowing Date</Label>
                                                <Input
                                                    type="text"
                                                    name="SowingDate"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined &&
                                                            SurveyInspectionSgl.Inspection !== undefined
                                                            ? SurveyInspectionSgl.Inspection.SowingDate
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>
                                            <Col lg="3" className="mb-2">
                                                <Label>Crop Name</Label>
                                                <Input
                                                    type="text"
                                                    name="CropName"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined
                                                            ? SurveyInspectionSgl.CropName
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>
                                            <Col lg="3" className="mb-2">
                                                <Label>Crop Variety</Label>
                                                <Input
                                                    type="text"
                                                    name="CropVariety"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined &&
                                                            SurveyInspectionSgl.Inspection !== undefined
                                                            ? SurveyInspectionSgl.Inspection.CropVariety
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>
                                            <Col lg="3" className="mb-3">
                                                <Label>Variety Name</Label>
                                                <Input
                                                    type="text"
                                                    name="VarietyName"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined &&
                                                            SurveyInspectionSgl.Inspection !== undefined
                                                            ? SurveyInspectionSgl.Inspection.VarietyName
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>
                                            <Col lg="3" className="mb-3">
                                                <Label>Is Irrigated</Label>
                                                <Input
                                                    type="text"
                                                    name="IsIrrigated"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined &&
                                                            SurveyInspectionSgl.Inspection !== undefined
                                                            ? SurveyInspectionSgl.Inspection.IsIrrigated
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>
                                            <Col lg="3" className="mb-3">
                                                <Label>Irrigation Source</Label>
                                                <Input
                                                    type="text"
                                                    name="IrrigationSource"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined &&
                                                            SurveyInspectionSgl.Inspection !== undefined
                                                            ? SurveyInspectionSgl.Inspection
                                                                .IrrigationSource
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>
                                            <Col lg="3" className="mb-3">
                                                <Label>Irrigation Count</Label>
                                                <Input
                                                    type="text"
                                                    name="IrrigationCount"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined &&
                                                            SurveyInspectionSgl.Inspection !== undefined
                                                            ? SurveyInspectionSgl.Inspection
                                                                .IrrigationCount
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>

                                            <Col lg="3" className="mb-3">
                                                <Label>Is Pestiscide</Label>
                                                <Input
                                                    type="text"
                                                    name="IsPestiscide"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined &&
                                                            SurveyInspectionSgl.Inspection !== undefined
                                                            ? SurveyInspectionSgl.Inspection
                                                                .IsPestiscide === true
                                                                ? "Yes"
                                                                : "No"
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>
                                            {SurveyInspectionSgl !== undefined &&
                                                SurveyInspectionSgl.Inspection !== undefined &&
                                                SurveyInspectionSgl.Inspection.IsPestiscide ===
                                                true ? (
                                                <Col lg="3" className="mb-3">
                                                    <Label>Pestiscide Name</Label>
                                                    <Input
                                                        type="text"
                                                        name="PestiscideName"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .PestiscideName
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                            ) : null}
                                            <Col lg="3" className="mb-3">
                                                <Label>Any Stress</Label>
                                                <Input
                                                    type="text"
                                                    name="AnyStress"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined &&
                                                            SurveyInspectionSgl.Inspection !== undefined
                                                            ? SurveyInspectionSgl.Inspection.AnyStress
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>
                                            <Col lg="3" className="mb-3">
                                                <Label>Stress Reason</Label>
                                                <Input
                                                    type="text"
                                                    name="StressReason"
                                                    id=""
                                                    defaultValue={
                                                        SurveyInspectionSgl !== undefined &&
                                                            SurveyInspectionSgl.Inspection !== undefined
                                                            ? SurveyInspectionSgl.Inspection.StressReason
                                                            : ""
                                                    }
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                                }

                                    </TabPane>
                                    <TabPane tabId={2}>

                                        {SurveyInspectionSgl.FormName === "CCEINT"? (
                                            <Row>
                                                <Col lg="3" className="mb-2">
                                                    <Label>Crop Variety</Label>
                                                    <Input
                                                        type="text"
                                                        name="CropVariety"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection.CropVariety
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-2">
                                                    <Label>Plot Shape</Label>
                                                    <Input
                                                        type="text"
                                                        name="PlotShape"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection.PlotShape
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-2">
                                                    <Label>Plot Size</Label>
                                                    <Input
                                                        type="text"
                                                        name="PlotSize"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection.PlotSize
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-2">
                                                    <Label>Biomass Weight</Label>
                                                    <Input
                                                        type="text"
                                                        name="BiomassWeight"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .BiomassWeight
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-3">
                                                    <Label>Grain Weight</Label>
                                                    <Input
                                                        type="text"
                                                        name="GrainWeight"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection.GrainWeight
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-3">
                                                    <Label>Area Calculated</Label>
                                                    <Input
                                                        type="text"
                                                        name="AreaCalculated"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .AreaCalculated
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                        ) : 
                                        
                                        SurveyInspectionSgl.FormName==="CCE"?
                                        
                                        <Row>
                                        <Col lg="3" className="mb-2">
                                        <Label>Size of Field</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerName"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.AreaCalculated
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>CCE Area</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerDesignation"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.CCEArea
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>CCE Shape</Label>
                                        <Input
                                          type="text"
                                          name="CCEShape"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.CCEShape
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Random Number</Label>
                                        <Input
                                          type="text"
                                          name="RandomNo"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.RandomNo
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Type of Land</Label>
                                        <Input
                                          type="text"
                                          name="PlotType"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.PlotType
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Sowing Date</Label>
                                        <Input
                                          type="text"
                                          name="SowingDate"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.SowingDate
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Cutting Date</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerMobile"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.CuttingDate
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Biomass Weight</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.BiomassWeight
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
      
                                       <Col lg="3" className="mb-2">
                                        <Label>Green Weight</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.GreenWeight
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Dry Weight Date</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.DryWeightDate
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Insurance Agent Present</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.IsInsAgent
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Reason for not Attending CCE</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.notAttendingCCEReason
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Harvest Loc</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.HarvestLoc
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Driage Ratio</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.DriageRatio
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Moisture Ratio</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.MoistureRatio
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Average Yield</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.AverageYield
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Production Obtained Through CCE</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.ProdObt
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Remarks about Production observed</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.ProdObtRemark
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Seed Used</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          style={{marginTop:"25px"}}
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.seedUsed
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
       
                                      <Col lg="3" className="mb-2">
                                        <Label>Seed Rate</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          style={{marginTop:"25px"}}
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.QuantityPerHeac
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Manure/FYM Used</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.ManureUsed
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Fertilizer used</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.IsFertilize
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                        <Label>Water Source</Label>
                                        <Input
                                          type="text"
                                          name="FeedbackerRemark"
                                          id=""
                                          defaultValue={
                                            SurveyInspectionSgl !== undefined &&
                                            SurveyInspectionSgl.Inspection.Part2 !== undefined
                                              ? SurveyInspectionSgl.Inspection.Part2.waterSource
                                              : ""
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col lg="3" className="mb-2">
                                      <Label>Soil Type</Label>
                                      <Input
                                        type="text"
                                        name="FeedbackerRemark"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part2 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part2.SoilType
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Officer Name</Label>
                                      <Input
                                        type="text"
                                        name="FeedbackerRemark"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part2 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part2.OfficerName
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Officer Mobile</Label>
                                      <Input
                                        type="text"
                                        name="FeedbackerRemark"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part2 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part2.OfficerMobile
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                      <Label>Officer Designation</Label>
                                      <Input
                                        type="text"
                                        name="FeedbackerRemark"
                                        id=""
                                        defaultValue={
                                          SurveyInspectionSgl !== undefined &&
                                          SurveyInspectionSgl.Inspection.Part2 !== undefined
                                            ? SurveyInspectionSgl.Inspection.Part2.OfficerDesig
                                            : ""
                                        }
                                        disabled
                                      />
                                    </Col>









                                      </Row>
                                        
                                        
                                      :  (
                                            <Row>
                                                <Col lg="3" className="mb-2">
                                                    <Label>NPK</Label>
                                                    <Input
                                                        type="text"
                                                        name="NPK"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection.NPK
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-2">
                                                    <Label>Crop Duration</Label>
                                                    <Input
                                                        type="text"
                                                        name="CropDuration"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .CropDuration
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-2">
                                                    <Label>Fertilizer Type</Label>
                                                    <Input
                                                        type="text"
                                                        name="FertilizerType"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .FertilizerType
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-2">
                                                    <Label>Last Year Crop Sown</Label>
                                                    <Input
                                                        type="text"
                                                        name="LastYearCropSown"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .LastYearCropSown
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>{" "}
                                                <Col lg="3" className="mb-3">
                                                    <Label>Last Year Yield</Label>
                                                    <Input
                                                        type="text"
                                                        name="LastYearYield"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .LastYearYield
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-3">
                                                    <Label>Last Season Crop Sown</Label>
                                                    <Input
                                                        type="text"
                                                        name="LastSeasonCropSown"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .LastSeasonCropSown
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-3">
                                                    <Label>Last Season Yield</Label>
                                                    <Input
                                                        type="text"
                                                        name="LastSeasonYield"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .LastSeasonYield
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-3">
                                                    <Label>Estimated Yield</Label>
                                                    <Input
                                                        type="text"
                                                        name="EstimatedYield"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .EstimatedYield
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-3">
                                                    <Label>Harvest Date</Label>
                                                    <Input
                                                        type="text"
                                                        name="HarvestDate"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection.HarvestDate
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                                <Col lg="3" className="mb-3">
                                                    <Label>Document Count</Label>
                                                    <Input
                                                        type="text"
                                                        name="DocumentCount"
                                                        id=""
                                                        defaultValue={
                                                            SurveyInspectionSgl !== undefined &&
                                                                SurveyInspectionSgl.Inspection !== undefined
                                                                ? SurveyInspectionSgl.Inspection
                                                                    .DocumentCount
                                                                : ""
                                                        }
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                        )}

























                                    </TabPane>
                                    <TabPane tabId={3}>
                                    {SurveyInspectionSgl.FormName === "CCE" ?
                                    <Row>
                                   
                                 
                                    {SurveyInspectionSgl !== undefined ||
                                      SurveyInspectionSgl.length!=0
                                      ? SurveyInspectionSgl &&
                                    
                                      <Row>
                                      {console.log("first",SurveyInspectionSgl.Inspection)}
                                      {console.log("second",SurveyInspectionSgl.Inspection.Part2)}
                                      {console.log("third",SurveyInspectionSgl.Inspection.Part2.Media)}
                                        
                                         {
                                          SurveyInspectionSgl.Inspection &&SurveyInspectionSgl.Inspection.Part2  &&SurveyInspectionSgl.Inspection.Part2.Media?
                                          SurveyInspectionSgl.Inspection.Part2.Media.map((list, index) =>
                                          list.ImagePath !== "" ? (
                                            <Col lg={4} key={list.index} className="mt-3"  >
                                        
                                              <Card className="text-center border ">
                                                <CardBody>
                                                  <div className="img-fluid float-left">
                                                    <p className="mb-1 text-uppercase">{list.Attribute}</p>
                                                    <hr className="mt-2" />
                                                   
                                                   {list.ImagePath.includes("mp4")?
                                                   <video width="240" height="320" controls>
                                                      <source src={list.ImagePath} type="video/mp4"/>
                                                       <source src={list.ImagePath} type="video/ogg"/>
                                                                         </video> 
                                                
                                                :  list.ImagePath.includes("pdf")?
                                                // <a href={ list.ImagePath} target="_blank"/>
                                                <object data={ list.ImagePath}
                                                      type='application/pdf' 
                                                     width='100%' 
                                                         height='400px'/>
                                                        //  <iframe src={list.ImagePath}
                                                        //               style={{width:"100%" , height:"400px"}} frameborder="0"></iframe>


                                                :
                                                <img src={ list.ImagePath}
                                                        
                                                    
                                                        alt=""
                                                        className="img-fluid"
                                                      />
                                                
                                                }
                                                    {console.log("imagepath",list.ImagePath.includes("mp4"),"  AttributeId",list.AttributeId)}
                                                 
                                                  </div>
                                                </CardBody>
                                              
                                              </Card>
                                            </Col>
                                          ) : null
                                        )
                                          :""
                                         }
                                      
                                      </Row>
                                      : null}
                                  
                                          <Row> {handleDynamicLine()?"No Data Found":""}</Row>  
                                
                                  </Row>
                               :
                                    <Row>
                                   
                                 
                                      {SurveyInspectionSgl !== undefined ||
                                        SurveyInspectionSgl.length!=0
                                        ? SurveyInspectionSgl &&
                                      
                                        <Row>
                                        
                                           {
                                            SurveyInspectionSgl.Inspection &&SurveyInspectionSgl.Inspection.Media?
                                            SurveyInspectionSgl.Inspection.Media.map((list, index) =>
                                            list.ImagePath !== "" ? (
                                              <Col lg={4} key={list.index} className="mt-3"  >
                                                <Card className="text-center border ">
                                                  <CardBody>
                                                    <div className="img-fluid float-left">
                                                      <p className="mb-1 text-uppercase">{list.Attribute}</p>
                                                      <hr className="mt-2" />
                                                      
                                                   {list.ImagePath.includes("mp4")?
                                                   <video width="320" height="240" controls>
                                                      <source src={list.ImagePath} type="video/mp4"/>
                                                       <source src={list.ImagePath} type="video/ogg"/>
                                                                         </video> 
                                                
                                                :  list.ImagePath.includes("pdf")?
                                    
                                                <object data={ list.ImagePath}
                                                      type='application/pdf' 
                                                     width='100%' 
                                                         height='400px'/>
                                                     


                                                :
                                                <img src={ list.ImagePath}
                                                        
                                                    
                                                        alt=""
                                                        className="img-fluid"
                                                      />
                                                
                                                }
                                                    </div>
                                                  </CardBody>
                                                
                                                </Card>
                                              </Col>
                                            ) : null
                                          )
                                            :""
                                           }
                                        
                                        </Row>
                                        : null}
                                        {isGallery ? (
                                            <Row>
                                            <Lightbox
                                            autoplay={false}
                                            open={isGallery}
                                            plugins={[Zoom]}
                                            close={() => setisGallery(false)}
                                            slides={[
                                              { src: agranilogo },
                                            
                                            ]}
                                          />
                                            
                                            </Row>
                                          ) : null}
                                  
                                    </Row>
                                    // <Row> {handleDynamicLineExceptCCE()?"No Data Found":""}</Row>  

                                        }
                                  </TabPane>
                                </TabContent>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>
                    </div>
                </Container>
            </div>
        </React.Fragment>

    )
}
export default Report
